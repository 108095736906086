<template>
  <OrdersTable></OrdersTable>
</template>

<script>
import OrdersTable from "@/view/components/tables/orders/OrdersTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    OrdersTable,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "detail-physical-orders" } },
      { title: this.$t("My Orders") },
    ]);
  },
};
</script>
